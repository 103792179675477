<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="作品名称">
        <el-input v-model="form.work_name"></el-input>
      </el-form-item>
      <el-form-item label="作者UCID">
        <el-input v-model="form.artist_ucid"></el-input>
      </el-form-item>
      <el-form-item label="作者昵称">
        <el-input v-model="form.artist_name"></el-input>
      </el-form-item>
      <el-form-item label="NFTtxid">
        <el-input v-model="form.txid"></el-input>
      </el-form-item>
      <el-form-item label="合规审核状态">
        <el-select v-model="form.shop_qualification_audit_status">
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="等待审核" :value="1"> </el-option>
          <el-option label="已通过" :value="2"> </el-option>
          <el-option label="已驳回" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="冻结状态">
        <el-select v-model="form.display_status">
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="公开" :value="1"> </el-option>
          <el-option label="冻结" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe row-key="ucid" @sort-change="handleSortChange">
        <el-table-column label="作品名称" prop="name"> </el-table-column>
        <el-table-column label="预览图">
          <template slot-scope="scope">
            <img :src="scope.row.cover" width="80" />
          </template>
        </el-table-column>
        <el-table-column label="NFTtxid" prop="txid"> </el-table-column>
        <el-table-column label="作者信息" prop="artist_ucid"
          ><template slot-scope="scope">
            {{ scope.row.creator_name + `(${scope.row.creator.ucid})` }}
          </template></el-table-column
        >
        <el-table-column label="铸造份数" prop="count"></el-table-column>
        <el-table-column label="合规审核状态" prop="shop_qualification_audit_status">
          <template slot-scope="scope">
            <el-tag v-if="+scope.row.shop_qualification_audit_status === 1" type="info"
              >等待审核</el-tag
            >
            <el-tag v-if="+scope.row.shop_qualification_audit_status === 2" type="success"
              >已通过</el-tag
            >
            <el-tag v-if="+scope.row.shop_qualification_audit_status === 3" type="danger"
              >已驳回</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="冻结状态" prop="display_status">
          <template slot-scope="scope">
            <el-button
              size="mini"
              :type="+scope.row.display_status === 1 ? 'primary' : 'danger'"
              @click="handleClickFroze(scope.row)"
              :loading="loading"
            >
              {{ +scope.row.display_status === 1 ? "公开" : "冻结" }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="提审时间" prop="shop_qualification_apply_at" sortable="custom">
          <template slot-scope="scope">
            <div v-if="scope.row.shop_qualification_apply_at">
              {{ scope.row.shop_qualification_apply_at | formatDate }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="处理时间" prop="shop_qualification_audit_at" sortable="custom">
          <template slot-scope="scope">
            <div v-if="scope.row.shop_qualification_audit_at">
              {{ scope.row.shop_qualification_audit_at | formatDate }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="+scope.row.shop_qualification_audit_status === 1"
              size="mini"
              type="primary"
              @click="handleAudit(scope.row)"
            >
              审核作品
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog title="作品审核" :visible.sync="visible" width="30%" center>
        <p>请预览作品后，仔细审核是否符合平台规则后再审核。</p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="() => handleOption(1)">通过审核</el-button>
          <el-button @click="() => handleOption(0)">驳回</el-button>
          <el-button @click="goView">作品预览</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getShopNFTList, EditShopNFT, setNFTStatus } from "@/api/nft.js";
import dayjs from "dayjs";
import config from "@/configs";

export default {
  components: {},
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      form: {},
      list: [],
      visible: false,
      // 查询参数
      ucid: "",
      mobile: "",
      email: "",
      sortBy: "",
      sortOrder: "",
      currentWork: {},
      loading: false,
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    formatMoney: function(value) {
      return value ? `+${value}` : "";
    },
  },
  computed: {
    params() {
      const {
        artist_ucid,
        artist_name,
        work_name,
        shop_qualification_audit_status,
        display_status,
        txid,
      } = this.form;
      return {
        artist_ucid: artist_ucid,
        artist_name: artist_name,
        audit_status: shop_qualification_audit_status,
        work_name: work_name,
        txid: txid,
        display_status: display_status,
        page: this.page,
        page_size: this.pageSize,
        sort_by: this.sortBy,
        sort_order: this.sortOrder,
      };
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.form = {
        artist_ucid: "",
        artist_name: "",
        shop_qualification_audit_status: 0,
        work_name: "",
        txid: "",
        display_status: 0,
      };
      this.page = 1;
      this.fetchList();
    },
    async fetchList() {
      try {
        const params = { ...this.params };
        const { data } = await getShopNFTList(params);
        this.list = data.data.items || [];
        this.total = data.data.total || 0;
      } catch (error) {
        this.list = [];
        this.total = 0;
      }
    },

    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSortChange(e) {
      const { prop, order } = e;
      this.sortBy = prop;
      this.sortOrder = order === "ascending" ? "asc" : "desc";
      this.fetchList();
    },
    handleAudit(row) {
      this.visible = true;
      this.currentWork = row;
    },
    handleOption(stauts) {
      this.EditShopNFT(stauts);
    },
    goView() {
      window.open(`${config.nftLiteUrl}/nft?txid=${this.currentWork.txid}`);
    },
    async EditShopNFT(status) {
      let params = {
        txid: this.currentWork.txid,
        type: status, //1通过 0驳回
      };
      try {
        const { data } = await EditShopNFT(params);
        this.$message({
          type: "success",
          message: "编辑成功!",
        });
        this.visible = false;
        this.fetchList();
      } catch (error) {
        this.visible = false;
        console.log(error);
      }
    },
    handleClickFroze(row) {
      const statusLabel = row.display_status === 1 ? "冻结" : "公开";
      this.$alert(
        `是否将「${row.name}」状态切换为「${statusLabel}」`,
        "作品状态变更",
        {
          confirmButtonText: "确定",
          callback: (action) => {
            if (action === "confirm") {
            this.handleChangeStatus(row);
            }
          },
        },
      );
    },
    async handleChangeStatus(row) {
      try {
        const nft_id = row.txid;
        const status = row.display_status == 1 ? 2 : 1;
        const { data } = await setNFTStatus(nft_id, status);
        this.$message.success("修改成功");
        this.visible = false;
        this.fetchList();
      } catch (error) {
        const msg = error?.data?.msg;

        if (msg) {
          this.$message.error(msg);
        }
      }
    },
  },
};
</script>
